.select-with-date input {
    color: rgba(0, 0, 0, 0.75) !important;
}

.select-with-date.ant-select-disabled span.ant-select-selection-item {
    color: rgba(0, 0, 0, 0.75) !important;
}

input.simulation-name.ant-input-disabled {
    color: rgba(0, 0, 0, 0.75);
}

.ant-input-number-sm input {
    height: 30px;
}